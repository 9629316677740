import React, { Suspense } from 'react'
import Spinner from './components/overlays/Spinner'
import { Route, Routes } from 'react-router-dom'
import Blank from './pages/Blank'
import ConfirmOrder from './pages/ConfirmOrder'

const Home = React.lazy(() => import('./pages/Home'))
const FullMenu = React.lazy(() => import('./pages/FullMenu'))
const ItemView = React.lazy(() => import('./pages/ItemView'))
const Cart = React.lazy(() => import('./components/cart/Cart'))
const ViewCategory = React.lazy(() => import('./pages/ViewCategory'))
const Checkout = React.lazy(() => import('./pages/Checkout'))
// const MenuFilters = React.lazy(() => import('./pages/MenuFilters'))
// const ChooseSize = React.lazy(() => import('./pages/ChooseSize'))

const AllRoutes = () => {
    return <Suspense fallback={<Spinner />}>
        <Routes>
            <Route path='/:slug' element={<Blank />}>
                <Route path='' element={<Home />} />
                <Route path='menu/all' element={<FullMenu />} />
                <Route path='menu/:itemid/' element={<ItemView />} />
                <Route path='category/:name' element={<ViewCategory />} />
                <Route path='cart' element={<Cart />} />
                <Route path='checkout' element={<Checkout />} />
                <Route path='orderConfirmed' element={<ConfirmOrder />} />
                {/* <Route path='/filters' element={<MenuFilters />} />
                    <Route path='/choosesize/:itemid' element={<ChooseSize />} /> */}
            </Route>
        </Routes>
    </Suspense>
}

export default AllRoutes