import { combineReducers } from "redux";

const merchantReducer = (state = {}, action) => {
    switch (action?.type) {
        case "SET_MERCHANT":
            localStorage.setItem("Merchant", JSON.stringify(action?.payload))
            return action?.payload
        case "LOGOUT":
            localStorage.removeItem("Merchant")
            return {}
        default:
            return state
    }
}

const cartReducer = (state = [], action) => {
    const cartText = localStorage.getItem("Cart")
    var cart = cartText ? JSON.parse(cartText) : []
    const newItem = action?.payload

    switch (action?.type) {
        case "ADD_ITEM":
            if (cart.some(item => item?.id === newItem?.id)) {
                cart = cart.map(item => item?.id === newItem?.id ? ({ ...item, count: item?.count + 1 }) : item)
            } else {
                cart.push({ ...newItem, count: 1 })
            }
            localStorage.setItem("Cart", JSON.stringify(cart))
            return cart

        case "REMOVE_ITEM":
            if (cart.some(item => item?.id === newItem && item?.count > 1)) {
                cart = cart.map(item => item?.id === newItem ? ({ ...item, count: item?.count - 1 }) : item)
            } else {
                cart = cart.filter(item => item?.id !== newItem)
            }
            localStorage.setItem("Cart", JSON.stringify(cart))
            return cart

        case "REMOVE_ALL_ITEMS":
            localStorage.setItem("Cart", JSON.stringify([]))
            return cart

        default:
            return cart ? cart : state
    }
}

const confirmedOrderReducer = (state = [], action) => {
    const order = localStorage.getItem("Order") ? JSON.parse(localStorage.getItem("Order")) : []
    const newOrder = action?.payload

    switch (action?.type) {
        case "ADD_ITEM":
            localStorage.setItem("Order", JSON.stringify(newOrder))    
            return order

        default:
            return order ? order : state
    }
}

const campaignsReducer = (state = [], action) => {
    switch (action?.type) {
        case "SET_ACTIVE_CAMPAIGNS":
            return action?.payload
        default:
            return state
    }
}

const recommendationsReducer = (state = [], action) => {
    switch (action?.type) {
        case "SET_RECOMMENDATIONS":
            return action?.payload
        default:
            return state
    }
}

export default combineReducers({
    merchantReducer,
    cartReducer,
    campaignsReducer,
    recommendationsReducer,
    confirmedOrderReducer
})