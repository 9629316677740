import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../components/navs/Navbar'

const Blank = () => {
    return <>
        <Navbar />
        <Outlet />
    </>
}

export default Blank