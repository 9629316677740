import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

const ConfirmOrder = () => {
    const order = useSelector((state) => state.confirmedOrderReducer);
    const totalCost = order.items
    .reduce((acc, item) => acc + item?.price * item?.count, 0)
    .toFixed(2);
    useEffect(() => {
        console.log('Order', order)
    }, [])

    return (
        <div className='flex flex-col items-center h-[90vh] mt-12'>
            <h1 className='m-6 text-[#000] text-center font-["Proxima_Nova"] text-[54px] not-italic font-bold leading-[normal]'>Order Confirmed</h1>
            <h5 className='text-[#000000] text-center font-["Proxima_Nova"] text-[28px] not-italic font-normal leading-[normal] m-7'>Order Confirmation: {order.orderId}</h5>

            <div className='rounded-[10px] flex flex-col justify-self min-w-[450px] bg-[#F9FAF7] p-6'>
                <span className='text-[#000] text-center font-["Proxima_Nova"] text-[32px] not-italic font-bold leading-[normal] my-4'>Order Review</span>
            {order.items.map((order, i) =><>
            <div>
                <div className='flex justify-between items-center my-3'>
                    <span className='text-[#000] font-["Proxima_Nova"] text-[24px] not-italic font-bold leading-[normal]'>{order.name}</span>
                    <span className='text-[#000] font-["Proxima_Nova"] text-[24px] not-italic font-bold leading-[normal]'>${order.price}</span>
                </div>
            </div>
            </>)}
            <hr className="h-0.5 border-t-1 bg-A2A2A6-100" />
            <div className='flex justify-between items-center my-3'>
                    <span className='text-[#000] font-["Proxima_Nova"] text-[24px] not-italic font-bold leading-[normal]'>Subtotal:</span>
                    <span className='text-[#000] font-["Proxima_Nova"] text-[24px] not-italic font-bold leading-[normal]'>${totalCost}</span>
                </div>
            </div>
            <div className="p-4 py-6">
                    <button
                        className={
                            "btn-primary border-0 shadow w-full py-4 disabled:bg-slate-300 min-w-[450px]"
                        }
                        
                    >
                        TRACK ORDER
                    </button>
                </div>
        </div>
    )
}

export default ConfirmOrder