
const Spinner = ({ small = false }) => {
    return small ?
        <div className="border-8 border-primary-50 border-b-slate-100 h-14 w-14 rounded-full animate-spin">

        </div> :
        <div className="flex justify-center items-center h-screen w-screen">
            <div className="border-8 border-primary-50 border-b-slate-100 h-14 w-14 rounded-full animate-spin">

            </div>
        </div>
}

export default Spinner