export const setMerchant = (data) => {
    return {
        type: "SET_MERCHANT",
        payload: data
    }
}

export const setActiveCampaigns = (data) => {
    return {
        type: "SET_ACTIVE_CAMPAIGNS",
        payload: data
    }
}

export const setRecommendations = (data) => {
    return {
        type: "SET_RECOMMENDATIONS",
        payload: data
    }
}

export const addItemToCart = (item) => {
    return {
        type: "ADD_ITEM",
        payload: item
    }
}

export const removeItemFromCart = (itemId) => {
    return {
        type: "REMOVE_ITEM",
        payload: itemId
    }
}

export const removeAllItemsFromCart = () => {
    return {
        type: "REMOVE_ALL_ITEMS",
    }
}

export const addConfirmOrder = (order) => {
    return {
        type: "ADD_ITEM",
        payload: order
    }
}

export const logout = () => {
    return {
        type: "LOGOUT"
    }
}
