import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as AlertProvider, positions, transitions } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createStore } from 'redux';
import Reducers from './reducers';
import AllRoutes from './AllRoutes';
import './index.css'

const options = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
}

const store = createStore(Reducers)

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <AlertProvider template={AlertTemplate} containerStyle={{ zIndex: 200 }} {...options}>
        <AllRoutes />
      </AlertProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);