import axios from 'axios'
import { getAPIUrl } from '../utilities/apiHelper'


 const baseURL = getAPIUrl()
    //const baseURL = window.location.origin
    //const baseURL = "http://localhost:8000"
    //const baseURL = "http://api.mysite.local:8000"

const API = axios.create({
    baseURL: baseURL
})

API.interceptors.request.use((req) => {
    if (localStorage.getItem('Profile')) {
        req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem('Profile')).access_token}`
    }
    return req;
})
API.interceptors.response.use(res => res,
    (error) => {
        if (!error.response) {

        } else {
            const response = error.response.data
            return Promise.reject(response)
        }
    })

// export const getCategoryList = (slug) => API.get(`/api/${slug}/positemcategory/list`);
export const getCategoryList = (slug) => API.post(`/ordering/items/category/`, { merchant: slug });
export const getMenuItem = (slug, id) => API.get(`/ordering/${slug}/item/${id}/`);
export const getMenuItems = (slug, query) => API.post(`/ordering/items/`, { merchant: slug, query });

export const getActiveCampaigns = (slug) => API.get(`/ordering/${slug}/campaigns/active`);
export const getRecommendations = (slug, campaignId, hr) => API.get(`/dashboard/${slug}/recommender/${campaignId}/?hour_of_day=${hr}`);

export const updateOrder = (slug, data) => API.post(`/ordering/${slug}/updateitem/`, data);
export const getCartItems = (slug) => API.post(`/ordering/${slug}/cartitems/`);

export const getMerchantDetails = (slug) => API.get(`/ordering/${slug}/merch/`);

export const pay = (data) => API.post(`/payments/pay/`,data);
export const placeOrder = (slug,data) => API.post(`/ordering/${slug}/place-order/`,data)

export const getAny = (url) => API.get(url);
// export const getMenuItems = (slug, page, data) => API.post(`/ordering/api/${slug}/positem/search?page=${page}`, data);