import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveCampaigns, setMerchant, setRecommendations } from "../../actions/index";
import NavLogo from "../../assets/images/nav_logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faBars, faCartShopping, faClose, faLanguage, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
import { getActiveCampaigns, getMerchantDetails, getRecommendations } from '../../api';

const Navbar = () => {
    const dispatch = useDispatch()
    const { slug } = useParams()

    const [isNavOpen, setIsNavOpen] = useState(false)
    const [loaders, setLoaders] = useState({ merchantData: true })
    const [merchantData, setMerchantData] = useState({})
    const Campaigns = useSelector((state) => state.campaignsReducer)

    useEffect(() => {
        if (slug) {
            getMerchantDetails(slug)
                .then(data => data.data)
                .then(data => {
                    dispatch(setMerchant({
                        merchant: data?.data
                    }))
                    setMerchantData(data?.data)
                }).catch()
                .finally(e =>
                    setLoaders(loaders => ({ ...loaders, merchantData: false })))

            if (!Campaigns?.length) {
                getActiveCampaigns(slug)
                    .then(data => data.data)
                    .then(campaigns => {
                        dispatch(setActiveCampaigns(campaigns?.data))
                    }).catch()
            } else {
                Promise.all(Campaigns.map(campaign =>
                    getRecommendations(slug, campaign?.id, new Date().getHours())
                ))
                    .then(resps => resps.map(resp => resp?.data))
                    .then(campaignRecommendationsList =>
                        dispatch(setRecommendations(campaignRecommendationsList)))
                    .catch()
            }
        }
    }, [])

    return (
        loaders.merchantData ? <></> : <div className='bg-white block xmd:flex justify-between max-w-[1000px] mx-auto border-b'>
            <div className='p-5 flex items-center justify-between'>
                <Link to={`/${slug}`}>
                    <img src={merchantData?.buisness_logo || NavLogo} alt="" className='h-11 block' />
                </Link>
                {!isNavOpen && <div onClick={() => setIsNavOpen(true)}
                    className='xmd:hidden rounded animate-fadeIn transition-all hover:bg-slate-200 cursor-pointer px-3 py-2'>
                    <FontAwesomeIcon icon={faBars} size='lg' className='' />
                </div>}
                {isNavOpen && <div onClick={() => setIsNavOpen(false)}
                    className='xmd:hidden rounded animate-fadeIn transition-all hover:bg-slate-200 cursor-pointer px-3 py-2'>
                    <FontAwesomeIcon icon={faClose} size='lg' className='' />
                </div>}
            </div>
            <div className={'gap-3 xmd:flex items-center transition-all animate-fadeIn ' + (!isNavOpen ? " hidden" : "")}>
                <div className='border-t xmd:border p-3 px-4 flex items-center justify-between gap-3 font-bold lg:h-10 lg:rounded-lg hover:bg-slate-100 cursor-pointer'><div>My Account</div> <FontAwesomeIcon className='' icon={faUserCircle} size='lg' /></div>
                <div className='border-t xmd:border p-3 px-4 flex items-center justify-between gap-3 font-bold lg:h-10 lg:rounded-lg hover:bg-slate-100 cursor-pointer'><div>English</div> <FontAwesomeIcon className='' icon={faLanguage} size='lg' /></div>
                <div className='border-t xmd:border p-3 px-4 flex items-center justify-between gap-3 font-bold lg:h-10 lg:rounded-lg hover:bg-slate-100 cursor-pointer'><div>Voice Order</div> <FontAwesomeIcon className='' icon={faMicrophone} size='lg' /></div>
                <div className='border-t xmd:border p-3 px-4 flex items-center justify-between gap-3 font-bold lg:h-10 lg:rounded-lg hover:bg-slate-100 cursor-pointer'><div>Cart</div> <FontAwesomeIcon className='' icon={faCartShopping} size='lg' /></div>
            </div>
        </div>
    )
}

export default Navbar